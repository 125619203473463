import React from 'react';
import 'twin.macro';

const Experiences = () => (
  <div tw="font-code">
    <h1>WiP</h1>
  </div>
);

export default Experiences;
